import { gql } from '@apollo/client';

const PAGE = gql`
  query GetPage($slug: String!) {
    findSlug(modelName: "page", slug: $slug, publicationState: "live") {
      ... on PageEntityResponse {
        data {
          __typename
          id
          attributes {
            createdAt
            name
            slug
            seo {
              __typename
              id
              title
              description
              blockSearchIndexing
            }
            components {
              ... on ComponentBookBookCards {
                __typename
                id
                bookCards {
                  __typename
                  id
                  heading
                  description
                  image {
                    data {
                      attributes {
                        url
                        alternativeText
                      }
                    }
                  }
                  button {
                    text
                    link
                    outlined
                    isExternal
                  }
                }
              }
              ... on ComponentBookBookAsideWithImage {
                __typename
                id
                heading
                description
                reverseContent
                image {
                  __typename
                  data {
                    attributes {
                      url
                      alternativeText
                    }
                  }
                }
                buttonGroup {
                  id
                  text
                  link
                  outlined
                  isExternal
                }
              }
              ... on ComponentContactFormContactForm {
                __typename
                id
                heading
                description
              }

              ... on ComponentIntroductionIntroduction {
                __typename
                id
                heading
                description
                image {
                  data {
                    attributes {
                      url
                      alternativeText
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

// const PAGE = gql`
//   query GetPage($slug: String!) {
//     pageBySlug(slug: $slug) {
//       id
//       slug
//       Title
//       published_at
//       Seo {
//         id
//         title
//         description
//         blockSearchIndexing
//       }
//       components {
//         __typename
//         ... on ComponentIntroductionIntroduction {
//           id
//           heading
//           description
//           image {
//             id
//             url
//             alternativeText
//             caption
//           }
//         }
//         ... on ComponentBookBook {
//           id
//           heading
//           description
//           image {
//             id
//             url
//             alternativeText
//             caption
//           }
//           buttonGroup {
//             id
//             Link
//             Text
//             outlined
//             isExternal
//           }
//           reverseContent
//         }
//       }
//     }
//   }
// `;

export { PAGE };
