import { Typography, Link } from '@mui/material';
import 'components/website-credit/website-credit.scss';

export default function WebsiteCredit() {
  return (
    <Typography
      align="center"
      component="p"
      variant="body1"
      sx={{
        textAlign: 'center',
        pt: 2,
        pb: 1,
        fontSize: 12,
        fontWeight: 300,
      }}
      className="website-credit"
    >
      Website by:
      <Link
        href="https://lemonbirdsolutions.com"
        rel="noreferrer"
        target="_blank"
        sx={{ ml: 1, color: 'primary.dark', fontWeight: 'normal' }}
        className="website-credit__link"
      >
        LemonBird
      </Link>
    </Typography>
  );
}
