import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Image from 'mui-image';
import { useTheme } from '@mui/material/styles';
import Markdown from 'markdown-to-jsx';
import {
  Container,
  Grid,
  Box,
  Typography,
  Button,
  Stack,
  Link,
  useMediaQuery,
} from '@mui/material';
import 'components/book/book.scss';

export default function BookAsideWithImage(props) {
  console.log('Book.js: props', props);
  const { props: data } = props;
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  return (
    <Container maxWidth="none" className="book-grid-container">
      <Grid
        container
        maxWidth="lg"
        sx={{
          mx: 'auto',
          py: { xs: 3, md: 6 },
          flexDirection: data.reverseContent ? 'row-reverse' : '',
          mb: 3,
        }}
        className="book-grid"
      >
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: 'column',
            px: { md: 6 },
          }}
          className="book-grid__item"
        >
          {data.image.data && (
            <Image
              src={
                process.env.REACT_APP_IMAGES_ENDPOINT +
                data.image.data.attributes.url
              }
              alt={data.image.data.attributes.alternativeText}
              showLoading
              duration={500}
              fit="contain"
              loading="lazy"
              sx={{
                mb: 2,
              }}
              wrapperStyle={{ height: 'auto' }}
              className="book-grid__image"
            />
          )}
          <Stack
            direction={isMobile ? 'column' : 'row'}
            spacing={4}
            sx={{ mb: { xs: 5, md: 0, width: isMobile ? '100%' : 'auto' } }}
            className="book-grid__buttons"
          >
            {data.buttonGroup.map((link) => {
              const buttonSx = {
                px: 6,
                textAlign: 'center',
                fontSize: 16,
              };
              const buttonProps = {
                key: link.id,
                variant: link.outlined ? 'outlined' : 'contained',
                className: 'book-grid__button',
              };
              return link.isExternal ? (
                <Button
                  href={link.link}
                  rel="noreferrer noopener"
                  target="_blank"
                  {...buttonProps}
                  sx={buttonSx}
                >
                  {link.text}
                </Button>
              ) : (
                <Button
                  to={link.link}
                  component={RouterLink}
                  {...buttonProps}
                  sx={buttonSx}
                >
                  {link.text}
                </Button>
              );
            })}
          </Stack>
        </Grid>
        <Grid item xs={12} md={7} sx={{ px: { md: 6 } }} className="book-grid">
          <Box className="book-content">
            <Typography
              component="h3"
              variant="h4"
              sx={{
                color: 'grey.800',
                textTransform: 'uppercase',
                fontWeight: 500,
                mb: 6,
              }}
            >
              {data.heading}
            </Typography>
            <Typography component="div">
              <Markdown>{data.description}</Markdown>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
