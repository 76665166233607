import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Image from 'mui-image';
import { useTheme } from '@mui/material/styles';
import Markdown from 'markdown-to-jsx';
import {
  Container,
  Grid,
  Box,
  Typography,
  Button,
  Stack,
  useMediaQuery,
} from '@mui/material';
import 'components/aside-with-image/aside-with-image.scss';

export default function AsideWithImage(props) {
  console.log('AsideWithImage.js: props', props);

  const { props: data } = props;
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  return (
    <Container
      maxWidth="none"
      className={`aside-with-image ${
        data.isContentCentered ? 'aside-with-image--centered' : null
      }`}
    >
      <Grid container maxWidth="lg" sx={{ mx: 'auto', py: 6 }}>
        <Grid item xs={12} md={7} sx={{ pr: { md: 6 } }}>
          <Box
            sx={{ px: { md: data.isContentCentered ? 8 : null } }}
            className={`aside-with-image__content ${
              data.isContentCentered
                ? 'aside-with-image__content--centered'
                : null
            }`}
          >
            <Typography
              component="h3"
              variant="h5"
              align={data.isContentCentered ? 'center' : 'inherit'}
              sx={{
                color: 'primary.main',
                textTransform: 'uppercase',
                textDecoration: 'underline',
                fontWeight: 500,
                mb: 3,
              }}
            >
              {data.subheading}
            </Typography>
            <Typography
              component="h3"
              variant="h4"
              align={data.isContentCentered ? 'center' : 'inherit'}
              sx={{
                color: 'grey.800',
                textTransform: 'uppercase',
                fontWeight: 500,
                mb: 6,
              }}
            >
              {data.heading}
            </Typography>
            <Typography
              component="div"
              align={data.isContentCentered ? 'center' : 'inherit'}
              sx={{
                mb: 6,
                color: 'grey.800',
                fontFamily: data.isContentCentered
                  ? 'Poppins, Arial, sans-serif'
                  : "Oswald, Roboto, 'Helvetica Neue', Arial, sans-serif",
                fontSize: data.isContentCentered ? 'inherit' : 20,
                fontWeight: data.isContentCentered ? 'inherit' : 300,
                textTransform: data.isContentCentered ? 'inherit' : 'uppercase',
              }}
            >
              {data.description && <Markdown>{data.description}</Markdown>}
            </Typography>
            <Stack
              direction={isMobile ? 'column' : 'row'}
              justifyContent={data.isContentCentered ? 'center' : 'flex-start'}
              spacing={4}
              sx={{ mb: { xs: 5, md: 0, width: isMobile ? '100%' : 'auto' } }}
            >
              {data.buttonGroup.map((link) => {
                const buttonSx = {
                  px: 6,
                  textAlign: 'center',
                };
                const buttonProps = {
                  key: link.id,
                  variant: link.outlined ? 'outlined' : 'contained',
                  className: 'book-grid__button',
                };
                return link.isExternal ? (
                  <Button
                    href={link.Link}
                    rel="noreferrer noopener"
                    target="_blank"
                    {...buttonProps}
                    sx={buttonSx}
                  >
                    {link.text}
                  </Button>
                ) : (
                  <Button
                    to={link.link}
                    component={RouterLink}
                    {...buttonProps}
                    sx={buttonSx}
                  >
                    {link.text} 
                  </Button>
                );
              })}
            </Stack>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
          }}
          className="aside-with-image__image"
        >
          <Image
            src={
              process.env.REACT_APP_IMAGES_ENDPOINT +
              data.image.data.attributes.url
            }
            alt={data.image.data.attributes.alternativeText}
            showLoading
            duration={500}
            fit="contain"
            loading="lazy"
            sx={{
              borderRadius: '10px 0 10px 0',
            }}
            wrapperStyle={{ height: 'auto' }}
            className="__Image"
          />
        </Grid>
      </Grid>
    </Container>
  );
}
