import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import Markdown from 'markdown-to-jsx';
import { Container, Typography, Divider } from '@mui/material';
import { PAGE } from 'queries/collection-types';
import { Loading, Error, NotFound } from 'components/status/Status';
import Hero from 'components/hero/Hero';
import Introduction from 'components/introduction/Introduction';
import BookAsideWithImage from 'components/book/BookAsideWithImage';
import BookCard from 'components/book/BookCard';
import ContactForm from 'components/contact-form/ContactForm';
import Head from 'components/_base/head/Head';
import { usePage } from 'lib/page';

export default function Page() {
  const { slug } = useParams();
  // const { loading, error, data } = useQuery(PAGE, {
  //   variables: {
  //     slug: slug,
  //   },
  // });
  const { data, isLoading, isError } = usePage(slug);
  // console.log('page data: ', data);

  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return <Error />;
  }
  if (data === null) {
    return <NotFound />;
  }
  console.log('PAGE.JS DATA: ', data);
  const { seo, slug: pageSlug, block } = data?.data?.attributes ?? {};

  return (
    <>
      {seo && <Head props={seo} slug={pageSlug} />}
      {block &&
        block.map((component) => {
          console.log("component", component)
          switch (component.__component) {
            case 'ComponentContactFormContactForm':
              return (
                <ContactForm
                  key={component.__component + '_' + component.id}
                  props={component}
                />
              );
            case 'text.introduction-text-larisa':
              return (
                <Introduction
                  key={component.__component + '_' + component.id}
                  props={component}
                />
              );
            case 'book.book-cards':
              return (
                <BookCard
                  key={component.__component + '_' + component.id}
                  props={component}
                />
              );
            case 'ComponentBookBookAsideWithImage':
              return (
                <BookAsideWithImage
                  key={component.__component + '_' + component.id}
                  props={component}
                />
              );
            default:
              return null;
          }
        })}
      {/* {page.Hero && <Hero props={page.Hero} />} */}
      {/* <Container
        sx={{
          py: 10,
        }}
      >
        <Typography
          component="h1"
          variant="h2"
          sx={{ mb: 10, overflowWrap: 'break-word' }}
        >
          <Divider textAlign="center">{page.Title}</Divider>
        </Typography>
        <Container
          sx={{
            width: { md: '75%' },
            // overflow: 'hidden',
            overflowWrap: 'break-word',
            wordWrap: 'break-word',
            wordBreak: 'break-word',
            hyphens: 'auto',
            whiteSpace: 'pre-wrap',
          }}
        >
          <Markdown>{page.Content}</Markdown>
        </Container>
      </Container> */}
    </>
  );
}
