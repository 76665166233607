import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Image from 'mui-image';
import Markdown from 'markdown-to-jsx';
import { Container, Grid, Box, Typography, Button, Stack } from '@mui/material';
import 'components/introduction/introduction.scss';

export default function Introduction(props) {
  // console.log("props", props)
  const { props: data } = props;
  console.log("data", data)

  return (
    <Container maxWidth="none" className="introduction-container">
      <Grid
        container
        maxWidth="lg"
        sx={{ mx: 'auto', py: { xs: 3, md: 10 } }}
        className="introduction-grid"
      >
        <Grid
          item
          xs={12}
          md={7}
          sx={{ pr: { md: 6 } }}
          className="introduction-grid__item"
        >
          <Box>
            <Typography
              component="h3"
              variant="h4"
              sx={{
                color: 'grey.800',
                textTransform: 'uppercase',
                fontWeight: 500,
                mb: 6,
              }}
            >
              {data.heading}
            </Typography>
            <Typography
              component="div"
              sx={{
                mb: 6,
                color: 'grey.800',
              }}
            >
              <Markdown>{data.description}</Markdown>
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
          }}
          className="aside-with-image__image"
        >
          { data.image.data && <Image
            key={data.image.id}
            src={
              data.image.data.attributes.url
            }
            alt={data.image.data.attributes.alternativeText}
            showLoading
            duration={500}
            fit="contain"
            loading="lazy"
            sx={{
              borderRadius: '10px 0 10px 0',
            }}
            wrapperStyle={{ height: 'auto' }}
            className="__Image"
          />}
        </Grid>
      </Grid>
    </Container>
  );
}
