import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Image from 'mui-image';
import Markdown from 'markdown-to-jsx';
import { Container, Grid, Box, Typography, Button, Stack } from '@mui/material';
// import 'components/introduction/introduction.scss';
import CarouselVertical from 'components/carousel/CarouselVertical';

export default function IntroductionWithCarousel(props) {
  console.log('IntroductionWithCarousel.js props: ', props);

  const { props: data } = props;

  return (
    <Container maxWidth="none" className="introduction-container">
      <Grid
        container
        maxWidth="lg"
        sx={{ mx: 'auto', py: { xs: 3, md: 10 } }}
        className="introduction-grid"
      >
        <Grid
          item
          xs={12}
          md={8}
          sx={{ pr: { md: 6 } }}
          className="introduction-grid__item"
        >
          {data?.introduction && (
            <Box>
              <Typography
                component="h3"
                variant="h4"
                sx={{
                  color: 'grey.800',
                  fontWeight: 500,
                  mb: 6,
                }}
              >
                {data?.introduction?.heading}
              </Typography>
              <Typography
                component="div"
                sx={{
                  mb: 6,
                  color: 'grey.800',
                }}
              >
                <Image
                  key={data?.introduction?.id}
                  src={
                    data.introduction.image.data.attributes.formats.small.url ??
                    data.introduction.image.data.attributes.url
                  }
                  alt={
                    data.introduction.image.data.attributes.alternativeText ??
                    data.introduction.image.data.attributes.name
                  }
                  showLoading
                  duration={500}
                  fit="cover"
                  width="auto"
                  loading="lazy"
                  sx={{
                    borderRadius: '50%',
                    width: '200px !important',
                    height: '200px !important',
                    objectPosition: 'center top',
                    float: 'left',
                  }}
                  wrapperStyle={{
                    float: 'left',
                    marginRight: '20px',
                    marginBottom: '20px',
                  }}
                  className="__Image111"
                />
                <Markdown>{data?.introduction?.description}</Markdown>
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
            alignItems: 'flex-start',
          }}
          className="aside-with-image__image"
        >
          <Typography
            component="h3"
            variant="h4"
            sx={{
              color: 'grey.800',
              textTransform: 'uppercase',
              fontWeight: 500,
              mb: 6,
            }}
          >
            {data?.carouselHeading}
          </Typography>
          <CarouselVertical data={data.slides} />
        </Grid>
      </Grid>
    </Container>
  );
}
