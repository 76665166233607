import { createTheme, responsiveFontSizes } from '@mui/material/styles';

/* 
https://mui.com/customization/typography/
https://mui.com/customization/default-theme/?expand-path=$.palette
https://mui.com/customization/default-theme/?expand-path=$.typography
https://mui.com/customization/color/
*/

let theme = createTheme({
  palette: {
    primary: {
      main: '#34C0AC',
      light: '#B1DED3',
      dark: '#00765A',
    },
    customPrimary: {
      main: '#ff3402',
      light: '#f9491e',
      dark: '#cd0e06',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: [
      'Oswald',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    body1: {
      fontFamily: 'Poppins, Arial, sans-serif',
    },
    serif: {
      fontFamily: '"Lora", serif',
    },
  },
});

theme = createTheme(theme, {
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: theme.palette.customPrimary.main,
        },
      },
    },
    MuiLink: {
      variant: 'h3',
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          color: theme.palette.common.white,
          fontFamily: "Oswald, Roboto, 'Helvetica Neue', Arial, sans-serif",
          fontSize: 20,
          borderWidth: 2,
          '&:hover': {
            borderWidth: 2,
          },
        },
      },
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            fontFamily: "Oswald, Roboto, 'Helvetica Neue', Arial, sans-serif",
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            color: theme.palette.primary.main,
          },
        },
        {
          props: { variant: 'primary', color: 'primary' },
          style: {
            border: '4px dashed blue',
          },
        },
      ],
    },
    MuiList: {
      styleOverrides: {
        root: {},
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: theme.palette.common.white,
          alignItems: 'stretch',
          fontFamily: "Oswald, Roboto, 'Helvetica Neue', Arial, sans-serif",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          color: theme.palette.common.white,
          fontFamily: "Oswald, Roboto, 'Helvetica Neue', Arial, sans-serif",
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default createTheme(theme);
