import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';
// import { Autoplay, Pagination, Navigation } from 'swiper';
import Image from 'mui-image';
import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import 'components/carousel/carousel.scss';
import CarouselImage from 'components/carousel/CarouselImage';

SwiperCore.use([Autoplay, Pagination, Navigation]);

export default function CarouselVertical(props) {
  console.log('CarouselVertical.js props: ', props);

  const slides = props?.data;
  console.log('slides', slides);

  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  console.log('isMobile', isMobile);

  const style = {
    // border: '5px solid red',
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '70%',
    maxHeight: '70%',
  };

  return (
    <Swiper
      // onSwiper={(swiper) => console.log(swiper)}
      direction={'vertical'}
      pagination={{
        dynamicBullets: true,
        clickable: true,
      }}
      navigation={true}
      loop={true}
      speed={2000}
      autoplay={{
        delay: 500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }}
      modules={[Autoplay, Pagination, Navigation]}
      className={isMobile || 'swiper--large'}
    >
      {slides?.map((slide) => {
        return (
          <SwiperSlide
            key={slide.id}
            style={{
              // border: '1px solid blue',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {slide?.link ? (
              <Button
                href={slide.link}
                rel="noreferrer noopener"
                target="_blank"
                // {...buttonProps}
                // sx={buttonSx}
                style={style}
              >
                <CarouselImage props={slide} />
              </Button>
            ) : (
              <span style={style}>
                <CarouselImage props={slide} />
              </span>
            )}
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}
