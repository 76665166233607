import React from 'react';
// import { useQuery } from '@apollo/client';
import Markdown from 'markdown-to-jsx';
import { Container, Stack } from '@mui/material';
// import { HOMEPAGE } from 'queries/single-type.js';
import AsideWithImage from 'components/aside-with-image/AsideWithImage';
import Testimonial from 'components/testimonial/Testimonial';
import Quote from 'components/quote/Quote';
import IntroductionWithCarousel from 'components/introduction/IntroductionWithCarousel';
import Introduction from 'components/introduction/Introduction';
import Carousel from 'components/carousel/Carousel';
import Cards from 'components/card/Card';
import Head from 'components/_base/head/Head';
import { Loading, Error, NotFound } from 'components/status/Status';
import { useHomepage } from 'lib/homepage';

export default function Homepage() {
  // const { loading, error, data } = useQuery(HOMEPAGE);

  const { data, isLoading, isError } = useHomepage();
  console.log('Homepage data: ', data);

  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return <Error />;
  }
  if (data === null) {
    return <NotFound />;
  }

  const { block, seo } = data?.data?.attributes ?? {};
  console.log('111111Homepage data: ', block);

  return (
    <>
      {seo && <Head props={seo} slug={'/'} />}
      {block &&
        block.map((component) => {
          switch (component.__component) {
            case 'ComponentIntroductionIntroduction':
              return (
                <Introduction
                  key={component.__component + '_' + component.id}
                  props={component}
                />
              );
            case 'text.introduction-with-carousel':
              return (
                <IntroductionWithCarousel
                  key={component.__component + '_' + component.id}
                  props={component}
                />
              );
            case 'ComponentCarouselSlides':
              return (
                <Carousel
                  key={component.__component + '_' + component.id}
                  props={component}
                />
              );
            case 'ComponentAsideWithImageAsideWithImage':
              return (
                <AsideWithImage
                  key={component.__component + '_' + component.id}
                  props={component}
                />
              );
            case 'ComponentTestimonialTestimonial':
              return (
                <Testimonial
                  key={component.__component + '_' + component.id}
                  props={component}
                />
              );
            case 'ComponentQuoteQuote':
              return (
                <Quote
                  key={component.__component + '_' + component.id}
                  props={component}
                />
              );
            default:
              return null;
          }
        })}
      {/* <Carousel props={homepage.homepageCarousel} /> */}
      {/* <Container sx={{ py: 10 }}>
        {homepage.Cards && <Cards props={homepage.Cards} />}
        <Stack sx={{ py: 10 }}>
          <Markdown>{homepage.content}</Markdown>
        </Stack>
      </Container> */}
    </>
  );
}
