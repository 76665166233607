import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Image from 'mui-image';
import { useTheme } from '@mui/material/styles';
import Markdown from 'markdown-to-jsx';
import {
  Container,
  Grid,
  Box,
  Typography,
  Button,
  Stack,
  Link,
  useMediaQuery,
  Card,
  CardActions,
  CardContent,
  CardMedia,
} from '@mui/material';
import 'components/book/book.scss';

export default function BookCard(props) {
  console.log('BookCard.js: props', props);

  const { bookCards } = props.props;

  console.log('BOOKCARD.JS: PROPS', bookCards);

  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  return (
    <Container maxWidth="none" className="book-grid-container">
      <Grid
        container
        maxWidth="lg"
        sx={{
          mx: 'auto',
          py: { xs: 3, md: 6 },
          mb: 3,
        }}
        className="book-grid"
      >
        {bookCards.map((book) => (
          <Grid
            key={book.id}
            item
            xs={12}
            md={3}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              px: { md: 1 },
            }}
            className="book-grid__item"
          >
            <Card sx={{ flexGrow: 1 }} className="CARD">
              <CardMedia
                component="img"
                height="140"
                image={
                  book.image.data.attributes.url
                }
                alt={book.image.data.attributes.alternativeText}
                sx={{ height: 'auto', objectFit: 'contain' }}
              />
              <CardContent>
                {book.heading && (
                  <Typography gutterBottom component="h3" variant="h5">
                    {book.heading}
                  </Typography>
                )}
                {book.descripton && (
                  <Typography gutterBottom>{book.descripton}</Typography>
                )}
              </CardContent>

              {book.button.map((button) => (
                <CardActions key={button.id} sx={{ p: 2 }}>

                 { button.isExternal ? (
                  <Button
                    href={button.link}
                    rel="noreferrer noopener"
                    target="_blank"
                    // {...buttonProps}
                    // sx={buttonSx}
                    className="card-link"
                    sx={{
                      display: 'block',
                      width: '100%',
                      textAlign: 'center',
                      p: 1,
                      fontSize: 16,
                      color: 'white',
                      backgroundColor: (theme) => theme.palette.primary.main,
                      ':hover' : {
                        backgroundColor: (theme) => theme.palette.primary.dark,
                      }
                    }}
                  >
                    {button.text}
                  </Button>
                ) : (
                  <Button
                    to={`/${button.link}`}
                    component={RouterLink}
                    // {...buttonProps}
                    // sx={buttonSx}
                    className="card-link"
                    sx={{
                      display: 'block',
                      width: '100%',
                      textAlign: 'center',
                      p: 1,
                      fontSize: 16,
                      color: 'white',
                      backgroundColor: (theme) => theme.palette.primary.main,
                      ':hover' : {
                        backgroundColor: (theme) => theme.palette.primary.dark,
                      }
                    }}
                  >
                    {button.text} 
                  </Button>
                )}


                  {/* <Link
                    variant="button"
                    href={button.link}
                    target="_blank"
                    sx={{
                      display: 'block',
                      width: '100%',
                      textAlign: 'center',
                      p: 1,
                      fontSize: 16,
                      color: 'white',
                      backgroundColor: (theme) => theme.palette.primary.main,
                    }}
                    className="card-link"
                  >
                    {button.text}
                  </Link> */}
                </CardActions>
              ))}
            </Card>
          </Grid>
        ))}

        {/* <Image
            src={
              process.env.REACT_APP_IMAGES_ENDPOINT +
              data.image.data.attributes.url
            }
            alt={data.image.data.attributes.alternativeText}
            showLoading
            duration={500}
            fit="contain"
            loading="lazy"
            sx={{
              mb: 2,
            }}
            wrapperStyle={{ height: 'auto' }}
            className="book-grid__image"
          /> */}

        {/* <Stack
            direction={isMobile ? 'column' : 'row'}
            spacing={4}
            sx={{ mb: { xs: 5, md: 0, width: isMobile ? '100%' : 'auto' } }}
            className="book-grid__buttons"
          >
            {data.buttonGroup.map((link) => {
              const buttonSx = {
                px: 6,
                textAlign: 'center',
                fontSize: 16,
              };
              const buttonProps = {
                key: link.id,
                variant: link.outlined ? 'outlined' : 'contained',
                className: 'book-grid__button',
              };
              return link.isExternal ? (
                <Button
                  href={link.link}
                  rel="noreferrer noopener"
                  target="_blank"
                  {...buttonProps}
                  sx={buttonSx}
                >
                  {link.text}
                </Button>
              ) : (
                <Button
                  to={link.link}
                  component={RouterLink}
                  {...buttonProps}
                  sx={buttonSx}
                >
                  {link.text}
                </Button>
              );
            })}
          </Stack> */}

        {/* <Grid item xs={12} md={7} sx={{ px: { md: 6 } }} className="book-grid">
          <Box className="book-content">
            <Typography
              component="h3"
              variant="h4"
              sx={{
                color: 'grey.800',
                textTransform: 'uppercase',
                fontWeight: 500,
                mb: 6,
              }}
            >
              {data.heading}
            </Typography>
            <Typography component="div">
              <Markdown>{data.description}</Markdown>
            </Typography>
          </Box>
        </Grid> */}
      </Grid>
    </Container>
  );
}
