import useSWR from 'swr';
const qs = require('qs');
const fetcher = (url) => fetch(url).then((res) => res.json());
const query = qs.stringify(
  {
    populate: ['block'],
    publicationState: 'live',
  },
  {
    encodeValuesOnly: true,
  }
);

const resource = `${process.env.REACT_APP_STRAPI_API}/navigation-larisa?${query}`;

export const useNavigation = () => {
  const { data, error } = useSWR(resource, fetcher, {
    refreshInterval: 30000,
  });

  // console.log('lib - navigation.ts: ', data);

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};
