import React from 'react';
// import { useQuery } from '@apollo/client';
import { Box, Container, Typography } from '@mui/material';
// import { GLOBAL } from 'queries/single-type.js';
import { Loading, Error, NotFound } from 'components/status/Status';
import FooterNavigation from 'components/navigation/footer-navigation/FooterNavigation';
import NewsLetterForm from 'components/newsletter-form/NewsletterForm';
import WebsiteCredit from 'components/website-credit/WebsiteCredit';
import 'components/_base/footer/footer.scss';
import { useSitewide } from 'lib/sitewide';

export default function Footer() {
  // const { loading, error, data } = useQuery(GLOBAL);
  const { data, isLoading, isError } = useSitewide();

  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return <Error />;
  }
  if (data === null) {
    return <NotFound />;
  }

  // console.log('GLOBAL DATA:', data);
  const { copyright } = data?.data?.attributes?.footer ?? {};

  return (
    <Box
      component="footer"
      sx={{ backgroundColor: 'common.white', py: 1 }}
      className="footer"
    >
      <Container disableGutters className="footer-container">
        {/* <NewsLetterForm /> */}
        {/* <FooterNavigation /> */}
        <Typography
          component="p"
          variant="body1"
          sx={{
            textAlign: 'center',
            pt: 2,
            fontSize: 14,
            fontWeight: 300,
          }}
        >
          &copy; {new Date().getFullYear()} {copyright}
        </Typography>
        <WebsiteCredit />
      </Container>
    </Box>
  );
}
