import React from 'react';
import Image from 'mui-image';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Markdown from 'markdown-to-jsx';
import { Container, Grid, Box, Typography, Link, Stack } from '@mui/material';
import 'components/quote/quote.scss';

export default function Quote(props) {
  const { props: data } = props;
  console.log('data', data);

  return (
    <Container maxWidth="none" className="testimonial">
      <Box maxWidth="lg" sx={{ mx: 'auto', py: { xs: 6, md: 12 } }}>
        <Typography
          component="div"
          variant="h4"
          align="center"
          sx={{ textTransform: 'uppercase', fontWeight: 300 }}
        >
          <Markdown>{`"${data.description}"`}</Markdown>- {data.authorName}
        </Typography>
      </Box>
    </Container>
  );
}
