import useSWR from 'swr';
const qs = require('qs');
const fetcher = (url) => fetch(url).then((res) => res.json());
const query = qs.stringify(
  {
    populate: [
      'seo',
      // 'openGraph',
      // "openGraph.ogImage",
      'block',
      'block.introduction',
      'block.slides',
      // "block.testimonialSlides",
      'block.introduction.image',
      'block.slides.image',
    ],
    publicationState: 'live',
  },
  {
    encodeValuesOnly: true,
  }
);

const resource = `${process.env.REACT_APP_STRAPI_API}/homepage-larisa?${query}`;

export const useHomepage = () => {
  const { data, error } = useSWR(resource, fetcher, {
    refreshInterval: 30000,
  });

  // console.log('lib - homepage.ts: ', data);

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};
