import Image from 'mui-image';

function CarouselImage(props) {
  console.log('CarouselImage.js props: ', props);

  const { url, alternativeText } = props.props.image.data.attributes;

  console.log('url', url);
  return (
    <Image
      src={url}
      alt={alternativeText}
      showLoading
      duration={500}
      fit="contain"
      style={{}}
      wrapperStyle={{
        width: 'auto',
        height: 'auto',
      }}
      sx={
        {
          // border: '5px solid green',
          // maxWidth: 'auto',
        }
      }
    />
  );
}

export default CarouselImage;
