import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import Markdown from 'markdown-to-jsx';
import 'components/testimonial/testimonial.scss';

export default function Testimonial(props) {
  // console.log('Testimonial.js props: ', props);
  const { props: data } = props;

  return (
    <Container maxWidth="none" className="testimonial">
      <Box
        maxWidth="lg"
        sx={{
          py: { xs: 6, md: 12 },
          mx: 'auto',
        }}
      >
        <Typography
          paragraph
          variant="h3"
          sx={{ textTransform: 'uppercase', color: 'grey.800' }}
        >
          {data.authorName}
        </Typography>
        <Typography
          paragraph
          variant="h5"
          sx={{ textTransform: 'uppercase', mb: 5, color: 'grey.800' }}
        >
          {data.authorPosition}
        </Typography>
        <Typography
          component="div"
          variant="h6"
          sx={{ textTransform: 'uppercase', fontWeight: 300 }}
        >
          <Markdown>{`"${data.description}"`}</Markdown>
        </Typography>
      </Box>
    </Container>
  );
}
