import useSWR from 'swr';
const qs = require('qs');
const fetcher = (url) => fetch(url).then((res) => res.json());
const query = qs.stringify(
  {
    populate: ['shared', 'footer'],
    publicationState: 'live',
  },
  {
    encodeValuesOnly: true,
  }
);

const resource = `${process.env.REACT_APP_STRAPI_API}/sitewide-larisa?${query}`;

export const useSitewide = () => {
  const { data, error } = useSWR(resource, fetcher, {
    refreshInterval: 30000,
  });

  // console.log('lib - sitewide.ts: ', data);

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};
