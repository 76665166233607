import { gql } from '@apollo/client';

const NAVIGATION = gql`
  query GetHomepage {
    navigation {
      __typename
      data {
        __typename
        id
        attributes {
          components {
            ... on ComponentNavigationLink {
              __typename
              id
              label
              url
              isShowInFooter
            }
            ... on ComponentNavigationNavigation {
              __typename
              id
              label
              links {
                __typename
                id
                label
                url
                isShowInFooter
              }
            }
          }
        }
      }
    }
  }
`;

const HOMEPAGE = gql`
  query GetHomepage {
    homepage {
      __typename
      data {
        attributes {
          seo {
            __typename
            id
            title
            description
            blockSearchIndexing
          }
          components {
            ... on ComponentIntroductionIntroduction {
              __typename
              id
              heading
              description
              image {
                data {
                  attributes {
                    url
                    alternativeText
                  }
                }
              }
            }
            ... on ComponentIntroductionCarousel {
              __typename
              id
              introduction {
                __typename
                id
                heading
                description
                image {
                  data {
                    attributes {
                      url
                      alternativeText
                      formats
                    }
                  }
                }
              }
              carouselHeading
              slides {
                __typename
                id
                link
                imageLarge {
                  data {
                    attributes {
                      url
                      alternativeText
                    }
                  }
                }
                imageSmall {
                  data {
                    attributes {
                      url
                      alternativeText
                    }
                  }
                }
              }
            }
            ... on ComponentCarouselSlides {
              __typename
              id
              slides {
                id
                link
                imageLarge {
                  data {
                    attributes {
                      url
                      alternativeText
                      formats
                    }
                  }
                }
              }
            }
            ... on ComponentQuoteQuote {
              __typename
              id
              authorName
              description
            }
            ... on ComponentTestimonialTestimonial {
              __typename
              id
              authorName
              authorPosition
              description
            }
            ... on ComponentAsideWithImageAsideWithImage {
              __typename
              id
              heading
              subheading
              description
              image {
                __typename
                data {
                  attributes {
                    url
                    alternativeText
                  }
                }
              }
              isContentCentered
              buttonGroup {
                id
                text
                link
                outlined
                isExternal
              }
            }
          }
        }
      }
    }
  }
`;

const GLOBAL = gql`
  query GetGlobal {
    global {
      __typename
      data {
        attributes {
          header {
            siteTitle
            siteSubtitle
          }
          footer {
            copyright
          }
        }
      }
    }
  }
`;

// const CONTACTPAGE = gql`
//   query GetContactPage {
//     contact {
//       __typename
//       id
//       slug
//       created_at
//       name
//       content
//       Seo {
//         id
//         title
//         description
//         blockSearchIndexing
//       }
//     }
//   }
// `;

export { NAVIGATION, HOMEPAGE, GLOBAL };
