import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Container,
  Grid,
  TextField,
  Button,
  Box,
  Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { green, red } from '@mui/material/colors';
import Fab from '@mui/material/Fab';
import EmailIcon from '@mui/icons-material/Email';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import Markdown from 'markdown-to-jsx';
import 'components/contact-form/contact-form.scss';

// const style = {
//   textField: {
//     my: 1,
//   },
// };

export default function ContactForm(props) {
  console.log('CONTACTFORM.js 1', props);
  const { props: data } = props;

  // const [loading, setLoading] = React.useState(false);
  // const [success, setSuccess] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);

  // const buttonSx = {
  //   width: '100%',
  //   fontSize: '16px',
  //   ...(success && {
  //     bgcolor: green[500],
  //     '&:hover': {
  //       bgcolor: green[700],
  //     },
  //   }),
  // };

  const style = {
    textField: {
      mb: 2,
    },
    formField: {
      minWidth: { xs: '100%', md: 400 },
    },
    formButton: {
      ...(success && {
        bgcolor: green[500],
        '&:hover': {
          bgcolor: green[700],
        },
      }),
      ...(error && {
        bgcolor: red[500],
        '&:hover': {
          bgcolor: red[700],
        },
      }),
    },
  };

  // const [name, setName] = useState('');
  // const [nameError, setNameError] = useState(false);
  // const [email, setEmail] = useState('');
  // const [emailError, setEmailError] = useState(false);
  // const [message, setMessage] = useState('');
  // const [messageError, setMessageError] = useState(false);

  // const [emailStatus, setEmailStatus] = useState('');
  // const [emailStatusText, setEmailStatusText] = useState('');
  // const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);

  const [message, setMessage] = useState('');
  const [messageError, setMessageError] = useState(false);

  const [emailStatus, setEmailStatus] = useState('');
  const [emailStatusText, setEmailStatusText] = useState('');

  const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);

  const resetInputFields = () => {
    setName('');

    setEmail('');
    setMessage('');
  };

  const theme = useTheme();

  const handleSubmit = (event) => {
    event.preventDefault();
    setNameError(false);
    setEmailError(false);
    setMessageError(false);

    if (name === '') {
      setNameError(true);
    }
    if (email === '') {
      setEmailError(true);
    }
    if (message === '') {
      setMessageError(true);
    }

    if (name && email && message) {
      // resetInputFields();
      // setIsEmailSubmitted(false);

      // if (!loading) {
      //   setSuccess(true);
      //   setLoading(false);
      // }

      setIsEmailSubmitted(false);

      if (!loading) {
        setSuccess(true);
        setLoading(false);
      }

      fetch(`${process.env.REACT_APP_ENDPOINT}/api/email`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ name, email, message }),
      })
        .then((response) => {
          setIsEmailSubmitted(true);

          return response.json().then((data) => {
            console.log('ContactForm Then Response: ', data);
            setSuccess(true);
            setError(false);
            setLoading(false);
            const { success, error } = data;
            setEmailStatus(success ? 'success' : 'error');
            setEmailStatusText(success || error);
            resetInputFields();

            return data;
          });
        })
        .catch((error) => {
          console.log('ContactForm Then Error: ', error);
          setError(true);
          console.log('Error: ', error);
        });
    }
  };
  return (
    <>
      <Container sx={{ py: 10 }}>
        <Grid container spacing={{ xs: 2, md: 4 }} sx={{ py: 10 }}>
          {(props.props.heading || props.props.description) && (
            <Grid item xs={12} md={6} className="foo">
              <Typography
                component="h3"
                variant="h4"
                sx={{ textTransform: 'uppercase' }}
              >
                {props.props.heading}
              </Typography>
              <Markdown>{props.props.description}</Markdown>
            </Grid>
          )}
          <Grid item xs={12} md={6} sx={{ margin: '0 auto' }} className="bar">
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              autoComplete="off"
              className="contact-form"
            >
              <TextField
                onChange={(event) => setName(event.target.value)}
                value={name}
                sx={{ ...style.textField }}
                label="Name"
                variant="outlined"
                fullWidth
                required
                error={nameError}
              />
              <TextField
                onChange={(event) => setEmail(event.target.value)}
                value={email}
                sx={{ ...style.textField }}
                label="Email"
                variant="outlined"
                fullWidth
                required
                error={emailError}
              />
              <TextField
                onChange={(event) => setMessage(event.target.value)}
                value={message}
                sx={{ ...style.textField }}
                label="Message"
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                required
                error={messageError}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'stretch',
                  my: { xs: 2, md: 4 },
                }}
              >
                <Box sx={{ m: 0, position: 'relative' }}>
                  <Button
                    // sx={buttonSx}
                    sx={style.formButton}
                    type="submit"
                    color="primary"
                    variant="contained"
                    size="large"
                    disabled={loading}
                    className="_Button"
                  >
                    Submit
                  </Button>
                  {loading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: green[500],
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                    />
                  )}
                </Box>
                {isEmailSubmitted && (
                  <Box sx={{ mt: 2 }}>
                    <Typography
                      sx={{
                        textAlign: 'left',
                        display: 'block',
                        color:
                          emailStatus === 'success'
                            ? theme.palette.success.main
                            : theme.palette.error.main,
                      }}
                    >
                      {emailStatusText}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
